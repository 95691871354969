<template>
  <div class="grid justify-content-center">
    <div class="col-12 md:col-12 mt-7">
      <Message @close="errorMsg = null" class="fadeinUp" v-if="errorMsg" severity="error" key="error">{{ errorMsg }}
      </Message>
      <div class="card text-center">
        <Avatar class="bg-orange-500 text-white" icon="pi pi-check-circle" size="large" />
        <div class="text-2xl mt-4 font-bold text-orange-500">
          {{ sentMsg }}
        </div>
        <div>
          {{ email }}
        </div>
        <Divider></Divider>
        <div class="text-center">
          <Button label="Resend Email" @click="resend()" icon="pi pi-envelope" :loading="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {},
    data: function () {
      return {
        loading: false,
        errorMsg: null,
        sentMsg: "Email verification link sent to your mailbox",
        email: this.$route.query.email,
      };
    },
    methods: {
      resend() {
        let url = "auth/resendverifyemail";
        this.loading = true;
        let formData = {
          token: this.$route.query.token,
        };
        this.$api.post(url, formData).then(
          (response) => {
            this.loading = false;
            this.sentMsg = response.data;
          },
          (request) => {
            this.loading = false;
            this.errorMsg =
              request?.response?.data || "Unable to complete request";
          }
        );
      },
    },
  };
</script>